import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const buttonGroupStyles = cva(
	[
		'w-fit flex gap-0 gap-0 [&>button]:rounded-none data-[orientation=vertical]:flex-col',
		'data-[orientation=horizontal]:[&>button:first-child]:rounded-l-md data-[orientation=horizontal]:[&>button:last-child]:rounded-r-md',
		'data-[orientation=vertical]:[&>button:first-child]:rounded-t-md data-[orientation=vertical]:[&>button:last-child]:rounded-b-md',
		'data-[orientation=horizontal]:[&>#button:first-child]:rounded-l-md data-[orientation=horizontal]:[&>#button:last-child]:rounded-r-md',
		'data-[orientation=vertical]:[&>#button:first-child]:rounded-t-md data-[orientation=vertical]:[&>#button:last-child]:rounded-b-md',
		'focus:[&>button]:z-10 select-none [&>#button]:rounded-none focus-within:[&>#button]:z-10',
	],
	{
		variants: {
			disabled: {true: 'pointer-events-none opacity-40'},
			orientation: {
				horizontal:
					'flex-row [&>button:first-child]:rounded-l-md [&>button:last-child]:rounded-r-md items-center',
				vertical:
					'flex-col [&>button:first-child]:rounded-t-md [&>button:last-child]:rounded-b-md justify-center',
			},
		},
		defaultVariants: {disabled: false, orientation: 'horizontal'},
	},
);

export type ButtonGroupVariantProps = VariantProps<typeof buttonGroupStyles>;
