import {forwardRef} from 'react';
import {Primitive} from '@radix-ui/react-primitive';
import {twMerge} from 'tailwind-merge';

import type {PrimitivePropsWithRef} from '@radix-ui/react-primitive';
import Paper from '../paper';
import {cardStyles} from './styles';
import type {PaperProps} from '../paper';
import type {CardVariantProps} from './styles';

export type CardTitleProps = PrimitivePropsWithRef<'h3'>;
const CardTitle = forwardRef<HTMLHRElement, CardTitleProps>(({className, ...props}, forwardedRef) => (
	<Primitive.h3
		{...props}
		className={twMerge('font-semibold leading-none tracking-tight', className)}
		ref={forwardedRef}
	/>
));

export type CardDescriptionProps = PrimitivePropsWithRef<'p'>;
const CardDescription = forwardRef<HTMLParagraphElement, CardDescriptionProps>(
	({className, ...props}, forwardedRef) => (
		<Primitive.p
			{...props}
			className={twMerge('text-sm leading-tight text-mauve11', className)}
			ref={forwardedRef}
		/>
	),
);

export type CardHeaderProps = PrimitivePropsWithRef<'div'>;
const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>(({className, ...props}, forwardedRef) => (
	<Primitive.div
		{...props}
		className={twMerge('flex items-center justify-between gap-2 text-mauve12', className)}
		ref={forwardedRef}
	/>
));

export type CardBodyProps = PrimitivePropsWithRef<'div'>;
const CardBody = forwardRef<HTMLDivElement, CardBodyProps>(({className, ...props}, forwardedRef) => (
	<Primitive.div {...props} className={twMerge('text-base text-mauve11', className)} ref={forwardedRef} />
));

export type CardFooterProps = PrimitivePropsWithRef<'div'>;
const CardFooter = forwardRef<HTMLDivElement, CardFooterProps>(({className, ...props}, forwardedRef) => (
	<Primitive.div {...props} className={twMerge('flex items-center gap-2', className)} ref={forwardedRef} />
));

export type CardProps = PaperProps & CardVariantProps;
const Card = Object.assign(
	forwardRef<HTMLDivElement, CardProps>(({className, ...props}, forwardedRef) => (
		<Paper {...props} className={twMerge(cardStyles(), className)} ref={forwardedRef} />
	)),
	{Header: CardHeader, Body: CardBody, Footer: CardFooter, Description: CardDescription, Title: CardTitle},
);

Card.displayName = 'Card';
Card.Header.displayName = 'Card.Header';
Card.Body.displayName = 'Card.Body';
Card.Footer.displayName = 'Card.Footer';
Card.Description.displayName = 'Card.Description';
Card.Title.displayName = 'Card.Title';

export default Card;

export * from './styles';
