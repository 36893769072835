export type LogoCloudItemProps = {
	src: string;
	alt: string;
};

export const LogoCloudItem = ({src, alt}: LogoCloudItemProps) => (
	<img
		alt={alt}
		className="col-span-2 max-h-20 w-full object-contain sm:col-span-1"
		height={48}
		src={src}
		width={158}
	/>
);
