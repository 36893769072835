import {createElement} from 'react';

import type {Icon} from '@job-ish/ui/types';

type FeaturesIconProps = {
	icon: Icon;
};

export const FeaturesIcon = ({icon}: FeaturesIconProps) => (
	<div className="rounded-md bg-violet7 p-0.5 group-hover:bg-gradient-to-br group-hover:from-violet11 group-hover:to-plum11">
		<div className="rounded-md bg-mauve2 p-2">
			{createElement(icon, {className: 'h-6 w-6 stroke-violet11'})}
		</div>
	</div>
);
