import {forwardRef} from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import {IconChevronDown} from '@tabler/icons-react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ReactNode} from 'react';
import {
	accordionContentStyles,
	accordionIconStyles,
	accordionItemStyles,
	accordionStyles,
	accordionTriggerStyles,
} from './styles';
import type {
	AccordionContentVariantProps,
	AccordionIconVariantProps,
	AccordionItemVariantProps,
	AccordionTriggerVariantProps,
	AccordionVariantProps,
} from './styles';

export type AccordionItemProps = AccordionPrimitive.AccordionItemProps & {
	trigger: ReactNode;
} & AccordionItemVariantProps &
	AccordionTriggerVariantProps &
	AccordionIconVariantProps &
	AccordionContentVariantProps;

const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
	({trigger, size, children, disabled, className, ...props}, forwardedRef) => (
		<AccordionPrimitive.Item
			{...props}
			className={twMerge(accordionItemStyles({disabled}), className)}
			disabled={disabled}
			ref={forwardedRef}
		>
			<AccordionPrimitive.Header>
				<AccordionPrimitive.Trigger className={accordionTriggerStyles({size})}>
					<div className="text-left">{trigger}</div>
					<IconChevronDown
						aria-hidden
						className={twMerge(
							accordionIconStyles({size}),
							'ml-2 flex-shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180',
						)}
					/>
				</AccordionPrimitive.Trigger>
			</AccordionPrimitive.Header>
			<AccordionPrimitive.Content className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
				<div className={accordionContentStyles({size})}>{children}</div>
			</AccordionPrimitive.Content>
		</AccordionPrimitive.Item>
	),
);

export type AccordionProps<T> = Omit<
	T extends 'single' ? AccordionPrimitive.AccordionSingleProps : AccordionPrimitive.AccordionMultipleProps,
	'orientation' | 'type'
> &
	AccordionVariantProps & {
		type: T;
	};

const Accordion = assign(
	<T extends 'single' | 'multiple'>({className, disabled, ...props}: AccordionProps<T>) => (
		<AccordionPrimitive.Accordion
			{...(props as AccordionPrimitive.AccordionSingleProps | AccordionPrimitive.AccordionMultipleProps)}
			className={twMerge(accordionStyles({disabled}), className)}
			disabled={disabled}
		/>
	),
	{Item: AccordionItem},
);

Accordion.Item.displayName = 'Accordion.Item';

export default Accordion;

export * from './styles';
