import AirbnbDark from '~/assets/images/brand-logos/airbnb-dark.svg';
import AirbnbLight from '~/assets/images/brand-logos/airbnb-light.svg';
import CapitalOneDark from '~/assets/images/brand-logos/capitalone-dark.svg';
import CapitalOneLight from '~/assets/images/brand-logos/capitalone-light.svg';
import HondaDark from '~/assets/images/brand-logos/honda-dark.svg';
import HondaLight from '~/assets/images/brand-logos/honda-light.svg';
import NVIDIADark from '~/assets/images/brand-logos/nvidia-dark.svg';
import NVIDIALight from '~/assets/images/brand-logos/nvidia-light.svg';
import {useColorModeStore} from '~/shared/stores/use-color-mode-store';
import {m} from 'framer-motion';

import {LogoCloudItem} from './logo-cloud-item';

export const LogoCloud = () => {
	const {colorMode} = useColorModeStore();

	return (
		<div className="px-4">
			<m.div
				className="mx-auto max-w-4xl pb-1 text-center text-3xl font-semibold text-mauve12 sm:text-4xl"
				variants={{visible: {opacity: 1, y: 0}, hidden: {opacity: 0, y: -15}}}
			>
				<h2>
					Join{' '}
					<span className="bg-gradient-to-br from-plum11 to-violet11 bg-clip-text text-transparent">
						thousands
					</span>{' '}
					of job seekers finding their{' '}
					<span className="bg-gradient-to-br from-plum11 to-violet11 bg-clip-text text-transparent">
						dream jobs
					</span>{' '}
					at top companies.
				</h2>
				<p className="pt-2 text-base text-mauve11">
					Find jobs sourced directly from the best companies in tech, finance, healthcare, and more.
				</p>
			</m.div>
			<div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-6">
				<LogoCloudItem alt="Honda" src={colorMode === 'dark' ? HondaDark.src : HondaLight.src} />
				<LogoCloudItem alt="NVIDIA" src={colorMode === 'dark' ? NVIDIADark.src : NVIDIALight.src} />
				<LogoCloudItem alt="Airbnb" src={colorMode === 'dark' ? AirbnbDark.src : AirbnbLight.src} />
				<LogoCloudItem
					alt="Capital One"
					src={colorMode === 'dark' ? CapitalOneDark.src : CapitalOneLight.src}
				/>
			</div>
		</div>
	);
};
