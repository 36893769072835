import {forwardRef} from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import {twMerge} from 'tailwind-merge';

import type {SetOptional} from 'type-fest';
import Button from '../button';
import IconButton from '../icon-button';
import type {ButtonProps} from '../button';
import type {IconButtonProps} from '../icon-button';

export type ToggleButtonProps = TogglePrimitive.ToggleProps &
	SetOptional<Omit<IconButtonProps, 'loading'>, 'icon'> &
	Omit<ButtonProps, 'loading'>;

const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
	({icon, intent = 'outline', pressed, children, className, ...props}, forwardedRef) => (
		<TogglePrimitive.Root
			{...props}
			asChild
			className={twMerge(className, 'scale-100')}
			pressed={pressed}
			ref={forwardedRef}
		>
			{icon ? <IconButton icon={icon} intent={intent} /> : <Button intent={intent}>{children}</Button>}
		</TogglePrimitive.Root>
	),
);

ToggleButton.displayName = 'ToggleButton';

export default ToggleButton;
