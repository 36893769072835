import {useEffect} from 'react';
import {Badge, Card} from '@job-ish/ui/components';
import {m, useAnimate, useInView} from 'framer-motion';

import type {Icon} from '@job-ish/ui/types';
import {FeaturesIcon} from './icon';

type FeaturesCardProps = {
	icon: Icon;
	title: string;
	description: string;
	comingSoon?: boolean;
	recentlyReleased?: boolean;
	planned?: boolean;
};

export const FeaturesCard = ({
	icon,
	title,
	description,
	comingSoon,
	recentlyReleased,
	planned,
}: FeaturesCardProps) => {
	const [ref, animate] = useAnimate();
	const inView = useInView(ref);

	useEffect(() => {
		if (inView)
			animate(ref.current, {
				opacity: 1,
				translateY: '0px',
				transition: {duration: 0.75},
				position: 'relative',
			});
	}, [animate, inView, ref]);

	return (
		<m.div
			className="group relative col-span-1 w-fit p-1"
			initial={{opacity: 0, translateY: '25px', position: 'absolute'}}
			ref={ref}
		>
			<div className="absolute left-0 top-0 h-full w-full rounded-md p-0.5" />
			<Card className="relative max-w-sm bg-mauve1">
				<Card.Body className="flex items-start justify-start gap-4">
					<FeaturesIcon icon={icon} />
					<div className="min-h-[5rem]">
						<div className="flex w-full items-center justify-between font-mono text-lg font-semibold text-mauve12">
							<span>{title}</span>
							{recentlyReleased && <Badge size="xs">NEW</Badge>}
							{comingSoon && <Badge size="xs">SOON</Badge>}
							{planned && <Badge size="xs">PLANNED</Badge>}
						</div>
						<p>{description}</p>
					</div>
				</Card.Body>
			</Card>
		</m.div>
	);
};
