import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const accordionStyles = cva('w-full rounded-sm focus-visible:outline-mauve8', {
	variants: {disabled: {true: 'opacity-40 pointer-events-none', false: ''}},
	defaultVariants: {disabled: false},
});

export const accordionItemStyles = cva('w-full border-b border-b-mauve6', {
	variants: {disabled: {true: 'opacity-40 pointer-events-none'}},
	defaultVariants: {disabled: false},
});

export const accordionTriggerStyles = cva(
	[
		'group flex w-full items-center justify-between text-mauve12',
		'select-none hover:bg-mauve4 focus-visible:outline focus-visible:outline-mauve8',
	],
	{
		variants: {size: {sm: 'px-1.5 py-1 text-sm', md: 'px-2.5 py-2 text-base', lg: 'px-3 py-3.5 text-lg'}},
		defaultVariants: {size: 'md'},
	},
);

export const accordionIconStyles = cva(undefined, {
	variants: {size: {sm: 'w-4 h-4', md: 'w-5 h-5', lg: 'w-6 h-6'}},
	defaultVariants: {size: 'md'},
});

export const accordionContentStyles = cva('text-base text-mauve11', {
	variants: {
		size: {
			sm: 'text-sm py-1 px-2 min-h-[2rem]',
			md: 'text-base py-1.5 px-2.5 min-h-[2.5rem]',
			lg: 'text-lg py-2 px-3 min-h-[3rem]',
		},
	},
	defaultVariants: {size: 'md'},
});

export type AccordionVariantProps = VariantProps<typeof accordionStyles>;
export type AccordionItemVariantProps = VariantProps<typeof accordionItemStyles>;
export type AccordionTriggerVariantProps = VariantProps<typeof accordionTriggerStyles>;
export type AccordionIconVariantProps = VariantProps<typeof accordionIconStyles>;
export type AccordionContentVariantProps = VariantProps<typeof accordionContentStyles>;
