import {forwardRef} from 'react';

import Button from '../button';
import type {ButtonProps} from '../button';

export type UnstyledButtonProps = Omit<ButtonProps, 'color' | 'size' | 'intent' | 'circular'>;

const UnstyledButton = forwardRef<HTMLElement, UnstyledButtonProps>(({children, ...props}, forwardedRef) => (
	<Button {...props} ref={forwardedRef} unstyled>
		{children}
	</Button>
));

UnstyledButton.displayName = 'UnstyledButton';

export default UnstyledButton;
