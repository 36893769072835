'use client';

import {Button, Card, TextButton} from '@job-ish/ui/components';
import {IconArrowRight} from '@tabler/icons-react';
import NextLink from 'next/link';

export const CTA = () => (
	<div className="w-full px-4">
		<Card className="mx-auto w-[32rem] max-w-full bg-transparent">
			<Card.Header className="flex-col justify-center gap-0 text-center text-3xl font-semibold">
				<Card.Title>
					<span>Get hired faster.</span>
					<span className="hidden sm:block">Accelerate your career.</span>
				</Card.Title>
			</Card.Header>
			<Card.Body className="flex flex-col text-center">
				<span>Sign up today and get access to every feature.</span>
			</Card.Body>
			<Card.Footer className="flex flex-col justify-center gap-3">
				<Button
					as="a"
					className="mx-auto w-64"
					color="primary"
					href="https://app.job-ish.com/auth/login"
					size="xl"
				>
					Get Started Now
				</Button>
				<TextButton as={NextLink} href="/#faq" iconRight={IconArrowRight} size="sm">
					Learn More
				</TextButton>
			</Card.Footer>
		</Card>
	</div>
);
