import {Accordion, type AccordionItemProps} from '@job-ish/ui/components';

export type FAQItemProps = Omit<AccordionItemProps, 'trigger'> & {
	trigger: string;
	activeQuestions: string[];
};

export const FAQItem = ({trigger, children, activeQuestions, ...props}: FAQItemProps) => (
	<>
		{!activeQuestions.includes(trigger) && <div className="hidden">{children}</div>}
		<Accordion.Item {...props} size="lg" trigger={trigger}>
			{children}
		</Accordion.Item>
	</>
);
