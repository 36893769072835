export const formatCurrency = (
	amount: number,
	options: Intl.NumberFormatOptions & {locale?: string | string[]} = {},
) => {
	try {
		return new Intl.NumberFormat(options.locale || 'en-us', {
			style: 'currency',
			maximumFractionDigits: 0,
			...options,
		}).format(amount);
	} catch {
		return new Intl.NumberFormat(options.locale || 'en-us', {
			style: 'currency',
			maximumFractionDigits: 0,
			currency: 'USD',
		}).format(amount);
	}
};
