import {forwardRef} from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import {buttonGroupStyles} from '../button-group/styles';
import ToggleButton from '../toggle-button';
import type {ButtonGroupVariantProps} from '../button-group/styles';
import type {ToggleButtonProps} from '../toggle-button';

export type ToggleButtonGroupItemProps = ToggleGroupPrimitive.ToggleGroupItemProps & ToggleButtonProps;
const ToggleButtonGroupItem = forwardRef<HTMLButtonElement, ToggleButtonGroupItemProps>(
	({children, icon, ...props}, forwardedRef) => (
		<ToggleGroupPrimitive.Item {...props} asChild>
			<ToggleButton icon={icon} ref={forwardedRef}>
				{children}
			</ToggleButton>
		</ToggleGroupPrimitive.Item>
	),
);

export type ToggleButtonGroupProps = ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
	ButtonGroupVariantProps;
const ToggleButtonGroup = assign(
	forwardRef<HTMLDivElement, ToggleButtonGroupProps>(({className, ...props}, forwardedRef) => (
		<ToggleGroupPrimitive.Root
			{...props}
			className={twMerge(buttonGroupStyles(props), className)}
			ref={forwardedRef}
		/>
	)),
	{Item: ToggleButtonGroupItem},
);

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

export default ToggleButtonGroup;
