import {forwardRef} from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import Paper from '../paper';
import type {PaperVariantProps} from '../paper/styles';

export type TooltipTriggerProps = TooltipPrimitive.TooltipTriggerProps;
const TooltipTrigger = forwardRef<HTMLButtonElement, TooltipTriggerProps>(
	({className, ...props}, forwardedRef) => (
		<TooltipPrimitive.Trigger {...props} className={twMerge('outline-none', className)} ref={forwardedRef} />
	),
);

export type TooltipContentProps = Omit<TooltipPrimitive.TooltipContentProps, 'asChild'> &
	Omit<PaperVariantProps, 'shadow' | 'padding' | 'bordered'>;
const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
	({children, className, ...props}, forwardedRef) => (
		<TooltipPrimitive.Portal>
			<TooltipPrimitive.Content {...props} asChild>
				<Paper
					bordered
					className={twMerge(
						'relative h-fit w-fit p-1 text-sm animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
						className,
					)}
					inverted
					padding="none"
					ref={forwardedRef}
					shadow
				>
					{children}
				</Paper>
			</TooltipPrimitive.Content>
		</TooltipPrimitive.Portal>
	),
);

export type TooltipProps = TooltipPrimitive.TooltipProps & TooltipPrimitive.TooltipProviderProps;
const Tooltip = assign(
	({delayDuration, disableHoverableContent, skipDelayDuration, ...props}: TooltipProps) => (
		<TooltipPrimitive.TooltipProvider
			delayDuration={delayDuration}
			disableHoverableContent={disableHoverableContent}
			skipDelayDuration={skipDelayDuration}
		>
			<TooltipPrimitive.Root {...props} />
		</TooltipPrimitive.TooltipProvider>
	),
	{Trigger: TooltipTrigger, Content: TooltipContent},
);

Tooltip.Trigger.displayName = 'Tooltip.Trigger';
Tooltip.Content.displayName = 'Tooltip.Content';

export default Tooltip;
