'use client';

import {useMemo} from 'react';
import {Badge, Card, Separator, ToggleButton, ToggleButtonGroup, Tooltip} from '@job-ish/ui/components';
import {formatCurrency} from '@job-ish/utilities/currency';
import {IconCheck, IconInfoCircle} from '@tabler/icons-react';

import type {Price} from '@job-ish/database/types';
import type {PropsWithChildren} from 'react';

export type PricingProps = PropsWithChildren<{
	prices?: Price[] | null;
	billingInterval: 'month' | 'year';
	setBillingInterval: (billingInterval: 'month' | 'year') => void;
}>;

const Pricing = ({prices, billingInterval, setBillingInterval, children}: PricingProps) => {
	const visiblePrice = useMemo(
		() => prices?.find(price => price.interval === billingInterval && price.active),
		[prices, billingInterval],
	);

	const annualPrice = useMemo(
		() => (prices?.find(price => price.interval === 'month' && price.active)?.unit_amount ?? 0) * 12,
		[prices],
	);

	return (
		<Card className="relative grow sm:h-fit sm:grow-0" padding="xl" shadow>
			<Card.Header>
				<ToggleButtonGroup className="w-full" type="single" value={billingInterval}>
					<ToggleButton
						className="grow"
						color="primary"
						onPress={() => setBillingInterval('month')}
						pressed={billingInterval === 'month'}
						size="sm"
						value="month"
					>
						Monthly
					</ToggleButton>
					<ToggleButton
						className="grow gap-2"
						color="primary"
						onPress={() => setBillingInterval('year')}
						pressed={billingInterval === 'year'}
						size="sm"
						value="year"
					>
						<Tooltip>
							<Tooltip.Trigger asChild>
								<Badge
									className="absolute -top-6 right-1/2 translate-x-1/2 transform"
									color="success"
									interactive
									size="sm"
								>
									Support the Team
									<IconInfoCircle className="hidden h-4 w-4 sm:block" />
								</Badge>
							</Tooltip.Trigger>
							<Tooltip.Content className="z-50 w-72">
								<p>
									<span className="font-bold">job-ish</span> is designed to help you get hired faster and an
									annual subscription is counterintuitive to that goal. You should only pay for an annual plan
									if you want to support the development of the product and the team behind it.
								</p>
							</Tooltip.Content>
						</Tooltip>
						<span>Yearly</span>
					</ToggleButton>
				</ToggleButtonGroup>
			</Card.Header>
			<Card.Body className="flex flex-wrap items-baseline justify-center gap-8 lg:justify-between">
				<div className="flex flex-col gap-3">
					<h2 className="text-2xl font-bold text-mauve12">Upgrade to Premium</h2>
					<p className="text-sm">Enhance your job search experience with more features.</p>
					<div className="flex w-full items-center gap-1">
						<span className="text-sm text-violet11">What you get</span>
						<Separator className="grow" />
					</div>
					<ul className="grid grid-cols-1 gap-2 sm:grid-cols-2">
						<li className="flex items-center gap-2">
							<IconCheck className="h-4 w-4 text-green9" />
							<span className="text-sm">Access to Premium Jobs</span>
						</li>
						<li className="flex items-center gap-2">
							<IconCheck className="h-4 w-4 text-green9" />
							<span className="text-sm">Cover Letter Generator</span>
						</li>
						<li className="flex items-center gap-2">
							<IconCheck className="h-4 w-4 text-green9" />
							<span className="text-sm">Kanban Application Tracker</span>
						</li>
						<li className="flex items-center gap-2">
							<IconCheck className="h-4 w-4 text-green9" />
							<span className="text-sm">Job Search Analytics</span>
						</li>
						<li className="flex items-center gap-2">
							<IconCheck className="h-4 w-4 text-green9" />
							<span className="text-sm">Personal Page Builder</span>
						</li>
						<li className="flex items-center gap-2">
							<IconCheck className="h-4 w-4 text-green9" />
							<span className="text-sm">Salary Insights</span>
						</li>
					</ul>
				</div>
				<div className="flex grow flex-col items-center">
					{visiblePrice && visiblePrice.unit_amount && (
						<div className="flex grow flex-col justify-end">
							<span className="text-lg text-blue11">One Price - Every Feature</span>
							<div className="mx-auto mt-1 flex h-12 items-baseline">
								{billingInterval === 'year' && (
									<span className="mr-1 text-3xl font-bold text-mauve11 line-through">
										{formatCurrency(annualPrice / 100, {currency: visiblePrice.currency ?? 'USD'})}
									</span>
								)}
								<span className="text-5xl font-bold text-mauve12">
									{formatCurrency(visiblePrice.unit_amount / 100, {
										currency: visiblePrice.currency ?? 'USD',
									})}
								</span>
								<span>/{billingInterval}</span>
							</div>
							{children}
						</div>
					)}
				</div>
			</Card.Body>
		</Card>
	);
};

export default Pricing;
